import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import mischief from './capsule.png';
import greetings from './greetings.png';
import ld55 from './ld55.png';
import gmtk from './swedish furniture behemoth.png';
import cardslap from './cardslap.png';
import fleacircus from './fleacircus title screen.png';

function Games() {
  return (
    <div className="games" id="games">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-header">
            GAMES
          </div>
        </div>
      </div>
      <div id="desktop-games">
        <div className="container game">
            <div className="col-md-8 game-image">
              <a href="https://store.steampowered.com/app/2844360/Mischief/"><img alt="Cover art for the video game 'Mischief,' featuring four playful rats on a colorful playground under a bright blue sky." src={mischief} /></a>
            </div>
            <div className="col-md-4 game-text">
              <div className="game-title">MISCHIEF</div>
              <a id="demo-link" href="https://store.steampowered.com/app/2844360/Mischief/"><div className="game-subtitle">FREE DEMO AVAILABLE ON STEAM</div></a>
              <div className="game-body">A delightful and imaginative action/adventure game where you step into the paws of a courageous rat, navigating through a captivating world within the nooks and crannies of a typical home and a bustling backyard. In solo or cooperative play with up to four players, you'll face whimsical boss characters and utilize a variety of household objects to either defeat or befriend them. Unearth hidden surprises, including intricate platforming courses, racing mini-games, and over 100 achievements, making Mischief an enchanting adventure suitable for all ages.</div>
            </div>
        </div>
        <div className="container game">
            <div className="col-md-4 game-text">
              <div className="game-title">FLEA CIRCUS</div>
              <div className="game-body"> Welcome to The Flea Circus! Flea T. Barnum needs your help to get our flea talent ready for showtime. You must work with each of our 6 flea performers to help choreograph a jaw-dropping routine that is sure to wow the audience. Earn money during performances to purchase upgrades at the Flea Market. Keep adding fleas until the audience says “Unbe-flea-able”. <br /> <br />Click the image to play!</div>
            </div>
            <div className="col-md-8 game-image">
              <a href="https://tavernratstudios.itch.io/flea-circus"><img alt="Title screen for 'Flea Circus' with a circus tent and two fleas in the background and the game name in white font on a hanging display. A start game button and a how to play button are visible on golden placards." src={fleacircus} /></a>
            </div>
        </div>
        <div className="container game">
            <div className="col-md-8 game-image">
              <a href="https://tavernratstudios.itch.io/swedish-furniture-behemoth"><img alt="Title screen for 'Swedish Furniture Behemoth' with blueprints in the background and the game name in yellow font. A start game button resembles a yellow credit card, accompanied by a sandbox mode button and text reading 'Made in 48 hours by the rats of Tavern Rat Studios.'" src={gmtk} /></a>
            </div>
            <div className="col-md-4 game-text">
              <div className="game-title">SWEDISH FURNITURE BËHÉMÖTH</div>
              <div className="game-body">Welcome to Swedish Furniture, where we provide all the furniture options you need to create your perfect mech. Whether you're a Bojo lover looking to grow your mech big and strong with our plant selection or building a more modern mech with an eye for defense who needs a mirror, we've got you covered. When it’s time to fight, fill your cart and your mech with the latest decor, watch your stats soar, and earn rare items exclusively for our most loyal shoppers. <br /> <br />Click the image to play!</div>
            </div>
        </div>
        <div className="container game">
            <div className="col-md-4 game-text">
              <div className="game-title">PHOTOGRAPHIC MEMORY</div>
              <div className="game-body">Photographic Memory is a game about looking back at your memories to help recall the things your friends and loved ones want to talk to you about. However, much like real memories they fade over time so don't look at them too long or they'll get blurry! Use the scrubber or the buttons to traverse your memories and click the summon memory thought bubble to show them to the person who's talking to you.<br /> <br />Click the image to play!</div>
            </div>
            <div className="col-md-8 game-image">
              <a href="https://tavernratstudios.itch.io/photographic-memory"><img alt="Gameplay from 'Photographic Memory' featuring a man saying, 'Yooo, long time no see! Oh me? Yeah, I've just been kickin' it at my mom's place and waiting for my latest hustle to take off. It's a pretty gnarly gig.' The screen also shows a photograph of a dog and a button to summon the memory." src={ld55} /></a>
            </div>
        </div>
        <div className="container game">
            <div className="col-md-8 game-image">
              <a href="https://tavernratstudios.itch.io/greeting-card-maker"><img alt="Title screen for 'Greeting Card Maker' displaying the game's name with a play button. The scene features confetti on a table with Sharpies scattered around." src={greetings} /></a>
            </div>
            <div className="col-md-4 game-text">
              <div className="game-title">GREETING CARD MAKER</div>
              <div className="game-body">Originally a game jam entry, step into the role of a card designer who picks up each new assignment without  knowing what message they might be writing. Try to fit the entire message onto the card without having to use a hyphen or get to the end of the card and be the best greeting card maker in town! <br /> <br />Click the image to play!</div>
            </div>
        </div>
        
        </div>
        <div id="mobile-games">
          <div className="container game">
              <div className="col-md-8 game-image">
                <a href="https://store.steampowered.com/app/2844360/Mischief/"><img alt="Cover art for the video game 'Mischief,' featuring four playful rats on a colorful playground under a bright blue sky." src={mischief} /></a>
              </div>
              <div className="col-md-4 game-text">
                <div className="game-title">MISCHIEF</div>
              <a id="demo-link" href="https://store.steampowered.com/app/2844360/Mischief/"><div className="game-subtitle">FREE DEMO AVAILABLE ON STEAM</div></a>
                <div className="game-body">A delightful and imaginative action/adventure game where you step into the paws of a courageous rat, navigating through a captivating world within the nooks and crannies of a typical home and a bustling backyard. In solo or cooperative play with up to four players, you'll face whimsical boss characters and utilize a variety of household objects to either defeat or befriend them. Unearth hidden surprises, including intricate platforming courses, racing mini-games, and over 100 achievements, making Mischief an enchanting adventure suitable for all ages.</div>
              </div>
          </div>
          <div className="container game">
            <div className="col-md-8 game-image">
              <a href="https://tavernratstudios.itch.io/swedish-furniture-behemoth"><img alt="Title screen for 'Swedish Furniture Behemoth' with blueprints in the background and the game name in yellow font. A start game button resembles a yellow credit card, accompanied by a sandbox mode button and text reading 'Made in 48 hours by the rats of Tavern Rat Studios.'" src={gmtk} /></a>
            </div>
            <div className="col-md-4 game-text">
              <div className="game-title">SWEDISH FURNITURE BËHÉMÖTH</div>
              <div className="game-body">Welcome to Swedish Furniture, where we provide all the furniture options you need to create your perfect mech. Whether you're a Bojo lover looking to grow your mech big and strong with our plant selection or building a more modern mech with an eye for defense who needs a mirror, we've got you covered. When it’s time to fight, fill your cart and your mech with the latest decor, watch your stats soar, and earn rare items exclusively for our most loyal shoppers. <br /> <br />Click the image to play!</div>
            </div>
        </div>
          <div className="container game">
              <div className="col-md-8 game-image">
                <a href="https://tavernratstudios.itch.io/photographic-memory"><img alt="Gameplay from 'Photographic Memory' featuring a man saying, 'Yooo, long time no see! Oh me? Yeah, I've just been kickin' it at my mom's place and waiting for my latest hustle to take off. It's a pretty gnarly gig.' The screen also shows a photograph of a dog and a button to summon the memory." src={ld55} /></a>
              </div>
              <div className="col-md-4 game-text">
                <div className="game-title">PHOTOGRAPHIC MEMORY</div>
                <div className="game-body">Photographic Memory is a game about looking back at your memories to help recall the things your friends and loved ones want to talk to you about. However, much like real memories they fade over time so don't look at them too long or they'll get blurry! Use the scrubber or the buttons to traverse your memories and click the summon memory thought bubble to show them to the person who's talking to you.<br /> <br />Click the image to play!</div>
              </div>
              
          </div>
          <div className="container game">
              <div className="col-md-8 game-image">
                <a href="https://tavernratstudios.itch.io/greeting-card-maker"><img alt="Title screen for 'Greeting Card Maker' displaying the game's name with a play button. The scene features confetti on a table with Sharpies scattered around." src={greetings} /></a>
              </div>
              <div className="col-md-4 game-text">
                <div className="game-title">GREETING CARD MAKER</div>
                <div className="game-body">Originally a game jam entry, step into the role of a card designer who picks up each new assignment without  knowing what message they might be writing. Try to fit the entire message onto the card without having to use a hyphen or get to the end of the card and be the best greeting card maker in town! <br /> <br />Click the image to play!</div>
              </div>
          </div>
          <div className="container game">
            <div className="col-md-8 game-image">
              <a><img alt="A three-pane image from the game 'Card Slap.' The first pane shows the title screen with an animated chihuahua, the game name, and UI buttons for play, options, store, how to play, and high score. The second pane displays a hand in a store with various accessories like bracelets, watches, and rings available for purchase. The third pane features a hand and a dog paw slapping a pile of cards." src={cardslap} /></a>
            </div>
            <div className="col-md-4 game-text">
              <div className="game-title">CARD SLAP</div>
              <div className="game-body">Come try this new fast-paced, one-handed card game! Play against Boomer the Dog in a mobile version of the classic card game, ERS. Take turns playing cards until you spot a double or a sandwich. SLAP! Grab those cards and take the whole pile. The first to take all the cards wins! <br /> <br /> Available on iOS and Android.</div>
            </div>
        </div>
        </div>
    </div>
  );
}


export default Games;